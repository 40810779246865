import { Collapse } from 'antd';
import styled from 'styled-components';

export const StyledCollapse = styled(Collapse)`
  &&& {
    background-color: #fff;
    .ant-collapse-header {
      border-radius: 0 0 8px 8px;
    }
    .ant-collapse-content {
      border: none;
    }
  }
`;

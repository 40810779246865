import { useTranslation } from 'react-i18next';
import { Form, Button, Select, Input, Row, Col, Segmented, App } from 'antd';
import {
  DURATION_TYPE,
  DURATION_TYPE_DATA,
  LIMIT_TYPE_DATA,
  SUBSCRIPTION_LIMIT_LIMITED,
  UNIT_OPTIONS,
  UNIT_OPTIONS_DATA
} from 'constants/subscriptions';
import { useEffect, useMemo, useState } from 'react';
import { LANGUAGES } from 'constants/languages';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_SUBSCRIPTION_FEATURE_ITEM,
  UPDATE_SUBSCRIPTION_FEATURE_ITEM
} from 'gql/subscription/mutations';
import {
  GET_SUBSCRIPTION_FEATURE_ITEM_BY_ID,
  GET_SUBSCRIPTION_FEATURE_ITEMS,
  GET_SUBSCRIPTION_FEATURES
} from 'gql/subscription/queries';
import { useNavigate, useParams } from 'react-router-dom';

import { PageWrapper } from 'components/ui';
import { getInputDir } from 'utils/helpers';
import { StyledCollapse } from '../styles';
import { showErrorMessage } from 'utils/showErrorMessage';

import {
  GetFeatureItemsByIdQuery,
  GetSubscriptionFeaturesQuery
} from '@/generated/graphql';

type OptionType = {
  limit: string;
  name: Record<string, string>;
  type: number;
};

type ValuesType = {
  subscription_feature_id: string;
  unit: string;
  option_1: OptionType;
  option_2: OptionType;
  option_3: OptionType;
};

const SubscriptionFeaturesDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [form] = Form.useForm();
  const [duration, setDuration] = useState(DURATION_TYPE.month);
  const [typeValues, setTypeValues] = useState<Record<number, number>>({});

  const { data } = useQuery<GetSubscriptionFeaturesQuery>(
    GET_SUBSCRIPTION_FEATURES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 100
          }
        }
      }
    }
  );

  const { data: featureItemData, loading } = useQuery<GetFeatureItemsByIdQuery>(
    GET_SUBSCRIPTION_FEATURE_ITEM_BY_ID,
    {
      fetchPolicy: 'no-cache',
      skip: !id,
      variables: {
        id
      }
    }
  );

  const featureItem = featureItemData?.getFeatureItemsById?.data;
  const unitValue = Form.useWatch('unit', form) || featureItem?.unit;

  const isHiddenOptions = () => {
    switch (unitValue) {
      case UNIT_OPTIONS.has_access:
        return true;
      case UNIT_OPTIONS.no_access:
        return true;
      default:
        return false;
    }
  };

  const allFeatures = useMemo(
    () => data?.getSubscriptionFeatures?.data.results,
    [data]
  );

  const [createFeatureItem, { loading: createLoading }] = useMutation(
    CREATE_SUBSCRIPTION_FEATURE_ITEM,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_FEATURE_ITEMS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.create_feature_success')
        });
        navigate('/subscriptions/features');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [updateFeatureItem, { loading: updateLoading }] = useMutation(
    UPDATE_SUBSCRIPTION_FEATURE_ITEM,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_FEATURE_ITEMS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.update_feature_success')
        });
        navigate('/subscriptions/features');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onFinish = (values: ValuesType) => {
    const { option_1, option_2, option_3, ...rest } = values;
    const hideOptions = isHiddenOptions();

    const formattedOption = [
      {
        ...option_1,
        duration_type: DURATION_TYPE.month
      },
      {
        ...option_2,
        duration_type: DURATION_TYPE.year
      },
      {
        ...option_3,
        duration_type: DURATION_TYPE.free
      }
    ];

    const formattedValues = {
      ...rest,
      value: 0,
      ...(hideOptions ? { options: null } : { options: formattedOption })
    };

    // if (hideOptions) {
    //   delete formattedValues.options;
    // }

    if (id) {
      return updateFeatureItem({ variables: { input: formattedValues, id } });
    }

    return createFeatureItem({ variables: { input: formattedValues } });
  };

  const onDurationChange = (newDuration: number) => {
    setDuration(newDuration);
  };

  const handleTypeChange = (value: number, durationType: number) => {
    setTypeValues(prev => ({ ...prev, [durationType]: value }));
  };

  useEffect(() => {
    if (featureItem) {
      const optionValues = featureItem.options?.reduce((acc, item) => {
        if (item && item.duration_type && item.name) {
          const { duration_type, limit, name, type } = item;

          if (limit) {
            setTypeValues(prev => ({ ...prev, [duration_type]: type }));
          }

          acc[`option_${duration_type}`] = {
            name,
            limit,
            type
          };
        }

        return acc;
      }, {} as Record<string, Record<string, number | number | null | undefined>>);

      form.setFieldsValue({
        subscription_feature_id: featureItem.subscription_feature_id,
        unit: featureItem.unit,
        ...optionValues
      });
    }
  }, [featureItem, form]);

  return (
    <PageWrapper
      loading={loading}
      back
      title={t(`subscription.${id ? 'update' : 'create'}_subscription_feature`)}
    >
      <StyledCollapse
        defaultActiveKey={['feature_details']}
        items={[
          {
            key: 'feature_details',
            label: t('subscription.feature_details'),
            children: (
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="subscription_feature_id"
                      label={t('subscription.select_feature')}
                      rules={[{ required: true }]}
                    >
                      <Select
                        size="large"
                        placeholder={t('subscription.select_feature')}
                        options={allFeatures?.map(item => ({
                          value: item.id,
                          label: item.name
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="unit"
                      label={t('subscription.set_measurement_unit')}
                      rules={[{ required: true }]}
                    >
                      <Select
                        size="large"
                        placeholder={t('subscription.set_unit')}
                      >
                        {UNIT_OPTIONS_DATA.map(item => (
                          <Select.Option
                            key={`unit-option-${item.label}`}
                            value={item.value}
                          >
                            {t(`subscription.${item.label}`)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {!isHiddenOptions() && (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Segmented
                        options={DURATION_TYPE_DATA.map(item => ({
                          ...item,
                          label: t(`subscription.${item.label}`)
                        }))}
                        block
                        size="large"
                        value={duration}
                        onChange={e => onDurationChange(e as number)}
                      />
                    </Col>
                    <Col span={24}>
                      {DURATION_TYPE_DATA.map(item => {
                        return (
                          <div
                            style={{
                              display:
                                item.value === duration ? 'block' : 'none'
                            }}
                            key={`form-${item.value}`}
                          >
                            <Row gutter={[16, 0]}>
                              {LANGUAGES.map(lang => (
                                <Col
                                  span={12}
                                  key={`${item.value}-${lang.key}`}
                                >
                                  <Form.Item
                                    name={[
                                      `option_${item.value}`,
                                      'name',
                                      lang.locale
                                    ]}
                                    label={`${t(
                                      'subscription.rename_feature'
                                    )} in ${lang.label}`}
                                  >
                                    <Input
                                      size="large"
                                      dir={getInputDir(lang.locale)}
                                      placeholder={t('subscription.rename')}
                                    />
                                  </Form.Item>
                                </Col>
                              ))}
                              <Col span={12}>
                                <Form.Item
                                  name={[`option_${item.value}`, 'type']}
                                  label={t('subscription.limit_type')}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    size="large"
                                    placeholder={t('subscription.set_limit')}
                                    onChange={value =>
                                      handleTypeChange(value, item.value)
                                    }
                                  >
                                    {LIMIT_TYPE_DATA.map(item => (
                                      <Select.Option
                                        key={`unit-option-${item.label}`}
                                        value={item.value}
                                      >
                                        {t(`subscription.${item.label}`)}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              {typeValues[duration] ===
                                SUBSCRIPTION_LIMIT_LIMITED && (
                                <Col span={12}>
                                  <Form.Item
                                    name={[`option_${item.value}`, 'limit']}
                                    label={t('subscription.limit')}
                                    rules={[
                                      {
                                        required: true,
                                        pattern: /^[0-9]+$/,
                                        message: 'Please enter a valid number'
                                      }
                                    ]}
                                  >
                                    <Input size="large" min={1} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                )}
                <Button
                  disabled={createLoading || updateLoading}
                  loading={createLoading || updateLoading}
                  type="primary"
                  htmlType="submit"
                >
                  {t('common.save')}
                </Button>
              </Form>
            )
          }
        ]}
      />
    </PageWrapper>
  );
};

export default SubscriptionFeaturesDetails;

import {
  App,
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Segmented,
  Select,
  Switch,
  Typography,
  Upload
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { useFileUpload } from 'hooks';
import {
  DURATION_TYPE,
  DURATION_TYPE_DATA,
  SUBSCRIPTION_STATUSES,
  UNIT_OPTIONS_VALUES
} from 'constants/subscriptions';
import { LANGUAGES } from 'constants/languages';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN
} from 'gql/subscription/mutations';
import {
  GET_SUBSCRIPTION_FEATURE_ITEMS,
  GET_SUBSCRIPTION_PLAN_BY_ID,
  GET_SUBSCRIPTION_PLANS
} from 'gql/subscription/queries';

import { PageWrapper } from 'components/ui';
import { StyledCollapse } from '../styles';
import { PATTERNS } from 'utils/validation';
import { getFileUrl } from 'utils/file';
import { getInputDir } from 'utils/helpers';
import { showErrorMessage } from 'utils/showErrorMessage';

import {
  GetFeatureItemsQuery,
  GetSubscriptionByIdQuery
} from '@/generated/graphql';

type OptionType = {
  status: number;
  price: number;
  most_popular: boolean;
  duration_type: number;
};

type FormType = {
  icon: File[];
  combine: string[];
  is_free: boolean;
  option_1: OptionType;
  option_2: OptionType;
};

const { Paragraph, Text } = Typography;

const SubscriptionPlansDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [imageUrl, setImageUrl] = useState<null | string>(null);
  const [duration, setDuration] = useState(DURATION_TYPE.month);
  const [isFree, setIsFree] = useState(false);
  const { upload } = useFileUpload({
    progress: true
  });

  const { data } = useQuery<GetFeatureItemsQuery>(
    GET_SUBSCRIPTION_FEATURE_ITEMS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 100
          }
        }
      }
    }
  );

  const { data: planItemData, loading } = useQuery<GetSubscriptionByIdQuery>(
    GET_SUBSCRIPTION_PLAN_BY_ID,
    {
      fetchPolicy: 'no-cache',
      skip: !id,
      variables: {
        id
      }
    }
  );

  const planItem = planItemData?.getSubscriptionById?.data;
  const subscriptionFeatures = useMemo(
    () => data?.getFeatureItems?.data.results,
    [data]
  );

  const [createPlanItem, { loading: createLoading }] = useMutation(
    CREATE_SUBSCRIPTION_PLAN,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_PLANS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.create_plan_success')
        });
        navigate('/subscriptions/plans');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [updatePlanItem, { loading: updateLoading }] = useMutation(
    UPDATE_SUBSCRIPTION_PLAN,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_PLANS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.update_plan_success')
        });
        navigate('/subscriptions/plans');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onDurationChange = (newDuration: number) => {
    setDuration(newDuration);
  };

  const onFinish = (values: FormType) => {
    const { option_1, option_2, ...rest } = values;

    const formattedOption = [
      {
        ...option_1,
        most_popular: Boolean(option_1?.most_popular),
        duration_type: DURATION_TYPE.month
      },
      {
        ...option_2,
        most_popular: Boolean(option_2?.most_popular),
        duration_type: DURATION_TYPE.year
      }
    ];

    const input = {
      ...rest,
      is_free: isFree,
      icon: imageUrl,
      combine: rest.combine.map((item: string) => +item),
      ...(isFree ? {} : { options: formattedOption })
    };

    if (isFree) {
      delete input.options;
    }

    if (id) {
      return updatePlanItem({ variables: { input, id } });
    }

    return createPlanItem({ variables: { input } });
  };

  useEffect(() => {
    if (planItem) {
      const optionValues = planItem.options?.reduce((acc, item) => {
        if (item && item.duration_type) {
          const { duration_type, most_popular, price, status } = item;

          acc[`option_${duration_type}`] = {
            most_popular,
            price,
            status
          };
        }

        return acc;
      }, {} as Record<string, Record<string, boolean | number | null | undefined>>);

      setIsFree(!!planItem.is_free);

      setImageUrl(planItem.icon || '');
      form.setFieldsValue({
        icon: planItem.icon
          ? [{ url: getFileUrl(planItem.icon), name: 'icon' }]
          : [],
        status: planItem.status,
        name: planItem.name,
        combine: planItem.features?.map(item => item?.item?.id),
        is_free: planItem.is_free,
        ...optionValues
      });
    }
  }, [planItem, form]);

  return (
    <PageWrapper
      loading={loading}
      back
      title={t(`subscription.${planItem?.id ? 'update' : 'create'}_plan`)}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <StyledCollapse
          defaultActiveKey={['plan_details']}
          items={[
            {
              key: 'plan_details',
              label: t('subscription.plan_details'),
              children: (
                <>
                  <Flex justify="space-between">
                    <Form.Item
                      name="icon"
                      label={t('subscription.add_icon')}
                      valuePropName="fileList"
                      rules={[{ required: true }]}
                      getValueFromEvent={e => {
                        if (e && e.fileList) {
                          return e.fileList;
                        }

                        return planItem?.icon
                          ? [{ url: planItem.icon, name: 'icon' }]
                          : [];
                      }}
                    >
                      <Upload
                        maxCount={1}
                        showUploadList={false}
                        customRequest={e => {
                          upload(e.file as File, {
                            onUploadProgress: e.onProgress
                          })
                            .then(e => {
                              e.onSuccess;
                              setImageUrl(e);
                            })
                            .catch(e.onError);
                        }}
                        name="icon"
                        listType="picture-card"
                      >
                        {imageUrl ? (
                          <Image
                            src={getFileUrl(imageUrl)}
                            preview={false}
                            height={100}
                            width={100}
                            style={{ objectFit: 'contain' }}
                          />
                        ) : (
                          <Flex vertical align="center" gap={8}>
                            <PlusOutlined style={{ color: '#2552F3' }} />
                            <Paragraph style={{ margin: 0, color: '#2552F3' }}>
                              {t('subscription.upload')}
                            </Paragraph>
                          </Flex>
                        )}
                      </Upload>
                    </Form.Item>
                    <Form.Item name="is_free">
                      <Flex align="center" gap={4}>
                        <Switch value={isFree} onChange={e => setIsFree(e)} />
                        <Text style={{ fontSize: 16, fontWeight: 500 }}>
                          {t('subscription.free')}
                        </Text>
                      </Flex>
                    </Form.Item>
                  </Flex>

                  <Row gutter={[16, 16]}>
                    {LANGUAGES.map(item => (
                      <Col span={12} key={item.key}>
                        <Form.Item
                          name={[`name`, item.locale]}
                          label={`${t('subscription.plan_name')} in ${
                            item.label
                          }`}
                          rules={[{ required: true }]}
                        >
                          <Input
                            size="large"
                            dir={getInputDir(item.locale)}
                            placeholder={t('subscription.rename')}
                          />
                        </Form.Item>
                      </Col>
                    ))}
                    <Col span={24}>
                      <Form.Item
                        name="combine"
                        label={t('subscription.select_features')}
                        rules={[{ required: true }]}
                      >
                        <Select
                          size="large"
                          mode="multiple"
                          allowClear
                          placeholder={t('subscription.select_features')}
                          options={subscriptionFeatures?.map(item => ({
                            value: item.id,
                            label: (
                              <Text>
                                {`${item.name}/`}
                                <Text style={{ fontWeight: 600 }}>
                                  {t(
                                    `subscription.${
                                      UNIT_OPTIONS_VALUES[
                                        item.unit as keyof typeof UNIT_OPTIONS_VALUES
                                      ]
                                    }`
                                  )}
                                </Text>
                              </Text>
                            )
                          }))}
                        >
                          {DURATION_TYPE_DATA.map(item => (
                            <Select.Option
                              key={`unit-option-${item.label}`}
                              value={item.value}
                            >
                              {t(`subscription.${item.label}`)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {!isFree && (
                    <>
                      <Divider>{t('subscription.options')}</Divider>
                      <Row>
                        <Col span={24}>
                          <Segmented
                            options={DURATION_TYPE_DATA.filter(
                              item => item.value !== DURATION_TYPE.free
                            ).map(item => ({
                              ...item,
                              label: t(`subscription.${item.label}`)
                            }))}
                            block
                            size="large"
                            value={duration}
                            onChange={e => onDurationChange(e as number)}
                          />
                        </Col>
                        <Col span={24}>
                          {DURATION_TYPE_DATA.filter(
                            item => item.value !== DURATION_TYPE.free
                          ).map(item => {
                            return (
                              <div
                                style={{
                                  display:
                                    item.value === duration ? 'block' : 'none'
                                }}
                                key={`form-${item.value}`}
                              >
                                <Row
                                  style={{ paddingTop: 16 }}
                                  align="middle"
                                  gutter={[16, 0]}
                                >
                                  <Col span={10}>
                                    <Form.Item
                                      name={[`option_${item.value}`, 'status']}
                                      label={t('subscription.select_status')}
                                    >
                                      <Select
                                        size="large"
                                        defaultValue={
                                          SUBSCRIPTION_STATUSES.active
                                        }
                                        placeholder={t(
                                          'subscription.select_status'
                                        )}
                                        options={Object.entries(
                                          SUBSCRIPTION_STATUSES
                                        ).map(([key, value]) => ({
                                          label: t(`subscription.${key}`),
                                          value
                                        }))}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={10}>
                                    <Form.Item
                                      name={[`option_${item.value}`, 'price']}
                                      label={t('subscription.price')}
                                      rules={[
                                        { required: true },
                                        { pattern: PATTERNS.price }
                                      ]}
                                    >
                                      <Input
                                        size="large"
                                        maxLength={255}
                                        placeholder="0.00"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      name={[
                                        `option_${item.value}`,
                                        'most_popular'
                                      ]}
                                    >
                                      <Flex gap={4} align="center">
                                        <Checkbox>
                                          <Text>
                                            {t('subscription.most_popular')}
                                          </Text>
                                        </Checkbox>
                                      </Flex>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </>
                  )}
                  <Form.Item>
                    <Button
                      disabled={createLoading || updateLoading}
                      loading={createLoading || updateLoading}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      style={{ width: 150 }}
                    >
                      {t('common.save')}
                    </Button>
                  </Form.Item>
                </>
              )
            }
          ]}
        />
      </Form>
    </PageWrapper>
  );
};

export default SubscriptionPlansDetails;

import { gql } from '@apollo/client';

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CreateSubscription($input: SubscriptionInput!) {
    createSubscription(input: $input) {
      message
    }
  }
`;
export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation UpdateSubscription($id: ID!, $input: SubscriptionInput!) {
    updateSubscription(id: $id, input: $input) {
      message
    }
  }
`;
export const DELETE_SUBSCRIPTION_PLAN = gql`
  mutation DeleteSubscription($id: ID!) {
    deleteSubscription(id: $id) {
      message
    }
  }
`;
export const DELETE_SUBSCRIPTION_FEATURE_ITEM = gql`
  mutation DeleteFeatureItems($id: ID!) {
    deleteFeatureItems(id: $id) {
      message
    }
  }
`;
export const CREATE_SUBSCRIPTION_FEATURE_ITEM = gql`
  mutation CreateFeatureItems($input: FeatureItemsInput!) {
    createFeatureItems(input: $input) {
      message
    }
  }
`;
export const UPDATE_SUBSCRIPTION_FEATURE_ITEM = gql`
  mutation UpdateFeatureItems($id: ID!, $input: FeatureItemsInput!) {
    updateFeatureItems(id: $id, input: $input) {
      message
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_PLANS = gql`
  query GetSubscriptionsForAdmin($query: SubscriptionsQuery) {
    getSubscriptionsForAdmin(query: $query) {
      data {
        total
        results {
          id
          icon
          name
          status
          options {
            status
            price
            most_popular
            duration_type
          }
          features {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_PLAN_BY_ID = gql`
  query GetSubscriptionById($id: ID!) {
    getSubscriptionById(id: $id) {
      data {
        id
        name
        icon
        status
        is_free
        options {
          status
          price
          most_popular
          duration_type
        }
        features {
          id
          icon
          name
          feature
          is_active
          item {
            id
            unit
            value
            subscription_feature_id
            options {
              id
              type
              limit
              name
              duration_type
            }
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_FEATURE_ITEMS = gql`
  query GetFeatureItems($query: FeatureItemsQuery) {
    getFeatureItems(query: $query) {
      data {
        total
        results {
          id
          value
          unit
          name
          subscription_feature_id
          options {
            id
            type
            limit
            duration_type
            name
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_FEATURE_ITEM_BY_ID = gql`
  query GetFeatureItemsById($id: ID!) {
    getFeatureItemsById(id: $id) {
      data {
        value
        unit
        subscription_feature_id
        options {
          id
          type
          limit
          name
          duration_type
        }
        id
      }
    }
  }
`;
export const GET_SUBSCRIPTION_FEATURES = gql`
  query GetSubscriptionFeatures($query: SubscriptionsFeaturesQuery) {
    getSubscriptionFeatures(query: $query) {
      data {
        total
        results {
          name
          id
          feature
          icon
          is_active
          items {
            options {
              id
              limit
              type
              name
              duration_type
            }
            id
            unit
            value
            subscription_feature_id
          }
        }
      }
    }
  }
`;
